/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams, useHistory } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import CpFormAgreement from "../community_partner/CpFormAgreement";
import getAppById from "./requests/getAppById";
import "./stepper.css";
import Spinner from "../../components/spinner/Spinner";
import EditApplicationModal from "./EditApplicantModal";
import getAppNotifications from "./requests/getAppNotifications";
import updateApplication from "./updateApplication";
import notify from "../../services/toast";
import { Link } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../constant";
import Logo from "../../assets/CommunityPartnerLogo.png";
import SULogo from "../../assets/StartUp.png";
import FileUploader from "../../components/file_uploader/FileUploader";
import Modal from "../../components/modal/Modal";
import ButtonPrimary from "../../components/button/ButtonSecondary";
import SelectInput from "../../components/select_input/SelectInput";
import { format } from "date-fns";
import ApplicationFile from "./ApplicationFile";
import config from "../../config";
import getAppsByUserId from "./requests/getAppsByUserId";
import { filter } from "lodash";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function CommunityPartnerProfile({ selectedApp }) {
	const [notification, setNotification] = useState("");
	const [showApplicantModal, setShowApplicantModal] = useState(false);
	const [showUploadFile, setShowUploadFile] = useState(false);
	const { userState } = useUserContext();
	const [appApprovedModal, setAppApprovedModal] = useState(false);
	const [appDeniedModal, setAppDeniedModal] = useState(false);
	const [denyReasonModal, setDenyReasonModal] = useState(false);
	const [application, setApplication] = React.useState({});
	const [apps, setApps] = useState("");
	const app = application;
	const params = useParams();
	const navigate = useNavigate();
	const [idFile, setIdFile] = useState("");

	const [isContacted, setIsContacted] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [scoreMemAwarded, setScoreMemAwarded] = useState();
	const [awardedMember, setAwardedMember] = useState();

	const [tabs, setTabs] = useState([
		{ name: "Overview" },
		{ name: "Agreement" },
		{ name: "Form" },
	]);

	const [tabYears, setTabYears] = useState([
		{ name: "2023" },
		{ name: "2024" },
	]);

	const [selectedTab, setSelectedTab] = useState("Overview");
	const initialState = apps && apps[0].createdAt;

	const [selectedTabYear, setSelectedTabYear] = useState(initialState);

	// const [selectedTabYear, setSelectedTabYear] = useState(apps && apps.length > 0 ? apps[0].createdAt : null);

	const changeTab = (year) => {
		setSelectedTab(year);
	};

	// Get years for applications
	const tabYear = [...new Set(apps ? apps.map((app) => app.createdAt) : null)];
	const filteredApplication = apps
		? apps.filter((app) => app.createdAt === selectedTabYear)
		: null;
	//	const selectedApp = filteredApplication ? filteredApplication[0] : null;
	const createdAt = selectedApp?.createdAt ? selectedApp?.createdAt : null;
	var submissionDate = new Date(createdAt);
	function addYears(date, years) {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}

	var newDate = addYears(submissionDate, 1);

	const orgAmountOPTIONS = [
		{ value: 0, label: "0" },
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];

	const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
	];
	const statusOPTIONS = [
		{ value: "rejected", label: "Rejected" },
		{ value: "approved", label: "Approved" },
	];
	const appOPTIONS = [
		{ value: "2023", label: "2023" },
		{ value: "2024", label: "2024" },
	];
	const [appYear, setAppYear] = useState();
	const denyReasons = [
		{ value: "At Capacity", label: "Location is at capacity" },
		{
			value: "Previous Member",
			label: "Applicant is a previous COhatch member",
		},
		{ value: "Current Member", label: "Applicant is a current COhatch member" },
		{ value: "Poor Fit", label: "Applicant was a Poor Fit" },
	];
	const fetchApplication = async () => {
		try {
			const response = await getAppById(
				userState.accessToken,
				params.applicationId
			);
			if (response.status === "success") {
				console.log(response.data);
				setApplication(response.data);
				//	fetchApplicationByUserId(userState.accessToken, response.data.userId);
			} else {
				console.log("error");
			}
		} catch (error) { }
	};

	const handleChange = (key, value) => {
		console.log(key, value);
		setPayload({
			...payload,
			[key]: value,
		});
	};

	const handleUpdate = (payload) => {
		if (payload.mlStatus === "approved") {
			setPayload({
				...payload,
				appStatus: "approved",
			});
			//	handleSubmit(payload);
			setAppApprovedModal(true);
		} else if (payload.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			//	handleSubmit(payload);
			setAppDeniedModal(true);
		}
		//	handleSubmit(payload);
	};

	const handleApproveUpdate = () => {
		handleSubmit(payload);
		setAppApprovedModal(false);
		//add confetti
	};

	const handleDenyUpdate = () => {
		handleSubmit(payload);
		setAppDeniedModal(false);
	};

	const handleGoBack = () => {
		setAppApprovedModal(false);
		setAppDeniedModal(false);
	};

	const handleSubmit = async (payload) => {
		console.log("submit", payload);
		try {
			const response = await updateApplication(
				userState.accessToken,
				payload,
				params.applicationId
			);
			if (response.status === "success") {
				console.log(response);
				notify("Applicaton was successfully updated.", "info");
				handleReload();
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			console.log(error);

			notify("Something went wrong. Please try again later.", "error");
		}
	};

	const handleReload = async () => {
		await fetchApplication();
	};
	// Updating Application
	const [payload, setPayload] = React.useState({
		firstName: selectedApp?.firstName,
		lastName: selectedApp?.lastName,
		email: selectedApp?.email,
		phoneNumber: selectedApp?.phoneNumber,
		cmStatus: selectedApp.cmStatus,
		mlStatus: selectedApp?.mlStauts,
		appStatus: selectedApp?.appStatus,
		officeRnd: selectedApp?.officeRnd,
		scoreMission: selectedApp?.scoreMission,
		scoreImpact: selectedApp?.scoreImpact,
		scoreFit: selectedApp?.scoreFit,
		scoreMemAwarded: selectedApp?.scoreMemAwarded,
		annualAward: selectedApp?.annualAward,
		denyReason: selectedApp?.denyReason,
		notes: selectedApp?.notes,
		awardedMember: selectedApp?.awardedMember,
		market: selectedApp?.market,
		coLocation: selectedApp?.coLocation,
		status501: selectedApp?.status501,
		dateFounded: selectedApp?.dateFounded,
		idNumber: selectedApp?.idNumber,
		applicantContacted: selectedApp?.applicantContacted,
	});

	console.log(payload);
	return (
		<>
			{appApprovedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center">
									<h1>
										Are you sure you would like to approve this application?
										Approving this application will notify the partner that they
										have been accepted.
									</h1>
									<ButtonPrimary
										size="md"
										text="Approve"
										action={handleApproveUpdate}
									/>
									<button
										className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
										size="md"
										onClick={handleGoBack}
									>
										Go Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{appDeniedModal && (
				<Modal>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
								<div className="w-full flex justify-between items-center ">
									<h1 className="m-10">
										Are you sure you would like to reject this application?
										Rejecting this application will send an email to the
										applicant informing them that they have not been chosen to
										receive a scholarship.
									</h1>
									<button
										className="w-full cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white bg-red-700 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
										size="md"
										onClick={handleDenyUpdate}
									>
										Reject
									</button>
									<button
										className="ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
										size="md"
										onClick={handleGoBack}
									>
										Go Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
			<div>
				<div className="overflow-hidden rounded-lg bg-white shadow">
					<div className="bg-white p-6">
						<div className="sm:flex sm:items-center sm:justify-between">
							<div className="sm:flex sm:space-x-5">
								<div className="flex-shrink-0">
									<img
										className="mx-auto h-20 w-20 rounded-full"
										src={Logo}
										alt=""
									/>
								</div>
								<div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
									<p className="text-xl font-bold text-gray-900 sm:text-2xl">
										{selectedApp?.firstName} {selectedApp?.lastName}
									</p>
									<p className="text-sm font-medium text-gray-600">Product Designer</p>
								</div>
							</div>
							<div className="mt-5 flex justify-center sm:mt-0">
								{selectedApp?.appStatus === "pending" ? (
									<div className="mt-3 inline-flex justify-center rounded-md bg-gray-400/80 px-3 py-2 text-sm font-semibold ">
										<span className="font-black">Pending</span>
									</div>
								) : selectedApp?.appStatus === "rejected" ? (
									<div className="mt-3 inline-flex justify-center rounded-md bg-red-600/80 px-3 py-2 text-sm font-semibold ">
										Rejected
									</div>
								) : selectedApp?.appStatus === "approved" ? (
									<div className="mt-3 inline-flex justify-center rounded-md bg-green-400/80 px-3 py-2 text-sm font-semibold ">
										Active
									</div>
								) : null}
							</div>
						</div>
					</div>

					{/* Tabs aligned in a grid like the new example */}
					<div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
						{tabs.map((tab, tabIdx) => (
							<div
								key={tab.name}
								onClick={() => setSelectedTab(tab.name)}
								className={`px-6 py-5 text-center text-sm font-medium cursor-pointer ${tab.name === selectedTab ? "bg-success-800 text-white" : "text-gray-600"
									} hover:bg-gray-200`}
							>
								<span>{tab.name}</span>
							</div>
						))}
					</div>
				</div>


				<div className="  grid grid-cols-6 gap-4 mb-16">
					{selectedTab === "Overview" && (
						<div className="mt-10 col-span-4">
							<div className="grid grid-cols-1 divide-y">
								<div className="mb-3">
									<h1 className="font-bold text-xl">
										{selectedApp.orgName}
									</h1>
									<div className="mt-7">
										<div className="mr-2 inline-flex justify-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-primary-100">
											<span className="capitalize">
												{selectedApp.cpCategory}
											</span>
										</div>

										{selectedApp?.causes?.map((cause, index) => {
											return renderCause(cause, index);
										})}
										{selectedApp?.category && (
											<div className="mr-2 inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ">
												<span className="capitalize">
													{selectedApp?.category}
												</span>
											</div>
										)}
									</div>
								</div>
								<div>
									{/* <div>
													<h1 className="mt-3 mb-3 font-bold text-xl">
														About {selectedApp?.orgName}
													</h1>
													<p className="mb-4">{selectedApp?.orgMission}</p>
												</div> */}
								</div>
								{/* Info Cards */}
							</div>
							{/* <div className="mt-4 mb-15 ">
								<ul role="list" className=" grid grid-cols-2 gap-6 ">
									<li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
										<div className="flex w-full items-center justify-between space-x-6 p-6">
											<div className="flex-1 truncate">
												<div className="flex items-center space-x-3">
													<h3 className="truncate text-sm font-medium text-primary-500">
														2023 Application File
													</h3>
												</div>
												
											</div>
										</div>
										<div className="flex justify-end pr-4">
											<Link
												to={`file/${selectedApp?.id}`}
												target="_blank"
											>
												View
											</Link>
										</div>
									</li>
								</ul>
							</div> */}
							<div className="mt-10 mb-10">
								<h1 className="font-bold text-black mb-4">
									Impact Stories from {selectedApp?.orgName}
								</h1>
								<p>No Submitted Stories</p>
							</div>
						</div>
					)}

					{/* SCORECARD */}
					{selectedTab === "Agreement" && (
						<div className="mt-10 col-span-4 w-3/4">
							<div className="">
								<h1 className="mb-6 font-bold text-2xl text-gray-800">Community Partner Agreement</h1>
								<div className="space-y-6">
									<div className="space-y-3">
										<label htmlFor="orgAlign" className="block text-md font-medium text-gray-800">
											What COhatch asks of this Community Partnership:
										</label>
										<textarea
											disabled
											className="block w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
											defaultValue={selectedApp?.ourAsks}
										/>
									</div>

									{/* <div className="space-y-3">
										<label htmlFor="orgMission" className="block text-md font-medium text-gray-800">
											Any other conditions of this partnership?
										</label>
										<textarea
											disabled
											className="block w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
											defaultValue={selectedApp?.extraConditions}
										/>
									</div> */}

									<div className="space-y-3">
										<label htmlFor="awardAmount" className="block text-md font-medium text-gray-800">
											Total Dollars Awarded in Coworking and/or Meeting & Event Space:
										</label>
										<input
											disabled
											className="block w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
											type="text"
											placeholder={selectedApp?.spaceAmount}
										/>
									</div>

									<div className="space-y-3">
										<label htmlFor="orgMission" className="block text-md font-medium text-gray-800">
											How many coworkers do you want to have utilizing COhatch?
										</label>
										<textarea
											disabled
											className="block w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
											defaultValue={selectedApp?.orgAmount}
										/>
									</div>

									<div className="space-y-3">
										<label htmlFor="awardAmount" className="block text-md font-medium text-gray-800">
											How long will this partnership be in effect? (In months)
										</label>
										<input
											disabled
											className="block w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
											type="text"
											placeholder={selectedApp?.termLength}
										/>
									</div>

									<div className="space-y-3">
										<label htmlFor="partnershipValue" className="block text-md font-medium text-gray-800">
											Description of Partnership Value (ex: # of coworking memberships, # of meeting room hours, etc.)
										</label>
										<input
											disabled
											className="block w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-lg shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
											type="text"
											placeholder={selectedApp?.partnershipValue}
										/>
									</div>
								</div>

								<div className="mt-10">
									<h1 className="mb-6 font-bold text-2xl text-gray-800">Status</h1>
									<div className="space-y-6">
										{userState.role.includes("MARKET_LEADER") || userState.role.includes("MASTER_APPROVAL") ? (
											<div className="space-y-3">
												<label className="block text-md font-medium text-gray-800">
													Market Leader Approval Status
												</label>
												<div className="mt-1">
													{selectedApp?.mlStatus === "pending" ? (
														<SelectInput
															className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
															options={statusOPTIONS}
															setSelectedValue={(evt) => handleChange("mlStatus", evt.value)}
															placeholder={String(selectedApp?.mlStatus).toUpperCase()}
														/>
													) : (
														<div className="block w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-500 bg-gray-100">
															{String(selectedApp?.mlStatus).toUpperCase()}
														</div>
													)}
												</div>
											</div>
										) : (
											<div className="space-y-3">
												<label className="block text-md font-medium text-gray-800">Market Leader Approval Status</label>
												<div className="block w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-500 bg-gray-100">
													{String(selectedApp?.mlStatus).toUpperCase()}
												</div>
											</div>
										)}

										<div className="space-y-3">
											<label className="block text-md font-medium text-gray-800">OfficeRnD Status</label>
											<SelectInput
												className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-primary-400 focus:border-primary-400"
												options={statusOPTIONS}
												setSelectedValue={(evt) => handleChange("officeRnd", evt.value)}
												placeholder={String(selectedApp?.officeRnd).toUpperCase()}
											/>
										</div>
									</div>
								</div>

								<div className="mt-8 flex justify-end">
									<button
										className="bg-primary-500 hover:bg-primary-200 text-white font-medium py-2 px-6 rounded-lg shadow-md focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
										type="button"
										onClick={() => {
											handleUpdate(payload);
										}}
									>
										Save Changes
									</button>
								</div>
							</div>
						</div>
					)}


					{selectedTab === "Form" && (
						<div className="mt-10 col-span-4 ">
							{selectedApp && (
								<CpFormAgreement applicationId={selectedApp.id} />
							)}
						</div>
					)}

					{/* Info Overview Section */}
					<div className="mt-10 col-span-2 text-center">
						<div className="rounded-md p-3 bg-white shadow-lg">
							{selectedApp?.type === "boost" &&
								selectedApp?.jobRole != null ? (
								<div className="mb-3">
									<h1 className="font-bold ">Job Role</h1>
									<p className="text-gray-600">{selectedApp?.jobRole}</p>
								</div>
							) : null}
							<div className="mb-3">
								<h1 className="font-bold">Location & Market</h1>
								<p className="capitalize text-gray-600">
									{selectedApp?.coLocation} | {selectedApp?.market}
								</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Email</h1>
								<p className="truncate hover:text-clip text-gray-600">
									{selectedApp?.email}
								</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Phone Number</h1>
								<p className="text-gray-600">
									{selectedApp?.phoneNumber}
								</p>
							</div>

							<div className="mb-3">
								<h1 className="font-bold ">Submission Date</h1>
								<p className="text-gray-600">
									<time>
										{format(new Date(createdAt), "MMMM do, yyyy")}
									</time>
								</p>
							</div>
							<div className="mb-3">
								<h1 className="font-bold ">Term Length</h1>
								<p className="text-gray-600">
									{selectedApp.termLength} months
								</p>
							</div>

							{/* <div className="mb-3">
											<h1 className="font-bold ">Applicant Contacted ?</h1>
											<button
												className={`bg-${
													isContacted ? "green" : "blue"
												}-500 hover:bg-${
													isContacted ? "green" : "blue"
												}-700 text-white font-bold py-2 px-4 border border-2 rounded focus:outline-none focus:shadow-outline`}
												style={{ color: "black" }} // Set contrasting text color
												//	onClick={handleClick}
											>
												{selectedApp?.applicantContacted
													? selectedApp?.applicantContacted
													: "No"}
											</button>
										</div> */}
							<div className="mb-3">
								<button
									className="cursor-pointer relative rounded shadow-sm px-2.5 py-2 text-white  bg-success-800 "
									size="md"
									onClick={() => setShowApplicantModal(true)}
								>
									Edit Partner
								</button>
								{selectedApp && (
									<EditApplicationModal
										open={showApplicantModal}
										onClose={() => setShowApplicantModal(false)}
										app={selectedApp}
									//	handleSubmit={handleSubmit}
									/>
								)}
							</div>
							{isModalOpen && (
								<Modal>
									<div className="fixed z-10 inset-0 overflow-y-auto">
										<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
											{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
											<span
												className="hidden sm:inline-block sm:align-middle sm:h-screen"
												aria-hidden="true"
											>
												&#8203;
											</span>
											<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
												<div className="w-full flex justify-between items-center">
													<div>
														<p>
															Are you sure you want to update the status?
														</p>
														<button
															className="mb-2 ml-3 w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
															onClick={handleConfirm}
														>
															Yes
														</button>
														<ButtonPrimary
															className="text-gray-700 block px-4 py-2 text-sm"
															text="Cancel"
															action={handleCancel}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Modal>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CommunityPartnerProfile;
